// returns the desired color in hex format
export function getHexColor(priority) {
	switch (priority) {
		case 'P1':
			return '#C60009';
		case 'P2':
			return '#FF6F00';
		case 'P3':
			return '#FFC300';
		case 'P4':
			return '#00A5CF';
		case 'P5':
			return '#7AE582';
		default:
			return '#7AE582';
	}
}

// returns the desired color in rgba format
export function getRGBAColor(priority) {
    switch (priority) {
		case 'P1':
			return 'rgba(198, 0, 9, 0.55)';
		case 'P2':
			return 'rgba(255, 111, 0, 0.55)';
		case 'P3':
			return 'rgba(255, 195, 0, 0.55)';
		case 'P4':
			return 'rgba(0, 165, 207, 0.55)';
		case 'P5':
			return 'rgba(122, 229, 130, 0.55)';
		default:
			return 'rgba(122, 229, 130, 0.55)';
	}
}
