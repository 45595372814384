import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
	auth: {
		clientId: `7b628446-26d3-439d-bd36-dcf8571d8f1e`,
		authority: `https://login.microsoftonline.com/1ea2b65f-2f5e-440e-b025-dfdfafd8e097`,
		redirectUri: '/',
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
};