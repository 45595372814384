import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { ThemeContextConsumer } from '../ThemeContext.js';

export default function SignOutButton() {
	const { instance } = useMsal();

	const handleSignOut = () => {
		instance.logoutRedirect();
	};
	return (
		<ThemeContextConsumer>
			{(context) => <Button onClick={handleSignOut} variant={context.theme}>Sign out</Button>}
		</ThemeContextConsumer>
	);
}