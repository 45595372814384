import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function Change(props) {
	return (
		<Row className={props.classes}>
			<a className='changes' href={props.link} style={{ height: '150px' }}>
				<Col className='alert-details'>
					<Row id='change-desc'>{props.desc}</Row>
					<Row className='text'>
						<Col xs={6} id='start-date'>
							{props.startTime}
						</Col>
						<Col xs={6} id='end-date'>
							{props.endTime}
						</Col>
					</Row>
				</Col>
			</a>
		</Row>
	);
}
