import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App.js';
import { ThemeContextProvider } from './ThemeContext.js';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig.js';
import { MsalProvider } from '@azure/msal-react';

const pca = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeContextProvider>
		<MsalProvider instance={pca}>
			<App />
		</MsalProvider>
	</ThemeContextProvider>
);
