import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Form, Nav, Navbar, Row } from 'react-bootstrap';
import { ThemeContextConsumer } from './ThemeContext.js';
import logo from './images/it_operations_seal_logo.png';
import { ReactComponent as SunLogo } from './images/sun.svg';
import { ReactComponent as MoonLogo } from './images/moon.svg';
import './App.scss';
import Incident from './components/Incident.js';
import axios from 'axios';
import Alerts from './components/Alerts.js';
import Change from './components/Change.js';
import {
	MsalProvider,
	useIsAuthenticated,
	useMsalAuthentication,
} from '@azure/msal-react';
import SignOutButton from './components/SignOutButton.js';
import SignInButton from './components/SignInButton.js';
import { InteractionType } from '@azure/msal-browser';

export default function App() {
	const isAuthenticated = useIsAuthenticated();
	const { result, error } = useMsalAuthentication(InteractionType.Redirect);
	const [isFullscreen, setIsFullscreen] = useState(
		document.fullscreenElement ? true : false
	);
	const [incidents, setIncidents] = useState([]);
	const [changes, setChanges] = useState([]);
	const [onCall, setOnCall] = useState('Please email outages@uvu.edu');
	const [teamOpts, setTeamOpts] = useState(null);
	const [alerts, setAlerts] = useState([]);
	let daySerializer = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
	let globalFullScreen = document.fullscreenElement ? true : false;
	let globalPriorityLevel = 'Priority 3+';
	let globalTeam = 'All';
	let globalSort = 'Created';
	// Format the time Hour:Minutes AM/PM
	function formatTime(timeStr) {
		// utility function to format time from a date string
		let time = timeStr.split(':');

		var hours = Number(time[0]);
		var minutes = Number(time[1]);

		let timeValue;

		if (hours > 0 && hours <= 12) {
			timeValue = '' + hours;
		} else if (hours > 12) {
			timeValue = '' + (hours - 12);
		} else if (hours == 0) {
			timeValue = '12';
		}

		timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes; // get minutes
		timeValue += hours >= 12 ? ' PM' : ' AM'; // get AM/PM

		return timeValue;
	}
	const [dateTime, setDateTime] = useState(
		`${daySerializer[new Date().getDay()]} - ${formatTime(
			new Date().getHours() + ':' + new Date().getMinutes()
		)}`
	);
	// Function to determine when the page is full screen
	const showDashboard = (event) => {
		let maxHeight = window.screen.height;
		let maxWidth = window.screen.width;
		let curHeight = window.innerHeight;
		let curWidth = window.innerWidth;

		if (
			maxWidth - 1 <= curWidth &&
			maxHeight - 1 <= curHeight &&
			maxWidth > 1024
		) {
			globalFullScreen = true;
			setIsFullscreen(true);
		} else {
			globalFullScreen = false;
			setIsFullscreen(false);
		}
	};
	// create alert components for each alert
	const createAlerts = (alerts) => {
		if (!globalFullScreen) {
			globalPriorityLevel = document.getElementById('priority-filter').value;
		}
		// If the priority level is set to 3+ remove P4 and P5 alerts
		if (globalPriorityLevel === 'Priority 3+') {
			alerts = alerts.filter((alertx) => {
				if (alertx.priority !== 'P5' && alertx.priority !== 'P4') {
					return alertx;
				}
			});
			
		}
		// set the alerts state variable
		if (alerts.length === 0) {
			setAlerts(<div className='no-content'>NO CURRENT ALERTS</div>);
		}else{setAlerts(
			alerts.map((alertx) => {
				if (alertx.acknowledged) {
					return (
						<Alerts
							ack={alertx.acknowledgedBy}
							date={alertx.date}
							priority={alertx.priority}
							desc={alertx.message}
						/>
					);
				} else {
					return (
						<Alerts
							flash='flash'
							ack={null}
							date={alertx.date}
							priority={alertx.priority}
							desc={alertx.message}
						/>
					);
				}
			})
		);}
	};
	// make a new call to get alerts based on changed filters
	const getFilteredAlerts = () => {
		if (!globalFullScreen) {
			globalTeam = document.getElementById('team-filter').value;
			globalSort = document.getElementById('sort-filter').value;
		}
		axios
			.get(`/alerts?sort=${globalSort}&team=${globalTeam}`)
			.then((result) => {
				if (result.data.length === 0) {
					setAlerts(<div className='no-content'>NO CURRENT ALERTS</div>);
				} else createAlerts(result.data);
			});
	};

	const getOutageData = () => {
		// call to get the incidents
		axios
			.get('/incidents')
			.then((res) => {
				// if nothing returned, create message
				if (res.data.length === 0) {
					setIncidents(<div className='no-content'>NO CURRENT INCIDENTS</div>);
				} else
					setIncidents(
						res.data.map((inc) => {
							return (
								<Incident
									key={inc.id}
									priority={inc.priority}
									date={inc.date}
									details={inc.description}
									link={inc.link}></Incident>
							);
						})
					);
			})
			.catch((err) => console.log(err));
		// call to get who is on call
		axios.get('/on-call').then((res) => setOnCall(res.data));
		// call to get the teams for the team filter
		axios
			.get('/alerts-filter')
			.then((res) => {
				setTeamOpts(
					res.data.map((team) => {
						return (
							<option key={team} value={team}>
								{team}
							</option>
						);
					})
				);
			})
			.catch((err) => console.log(err));
		// call to get the alerts
		getFilteredAlerts();
		// call to get the scheduled changesg
		axios
			.get('/change')
			.then((res) => {
				// if nothing returned, create message
				if (res.data.length === 0) {
					setChanges(<div className='no-content'>NO SCHEDULED CHANGES</div>);
				} else
					setChanges(
						res.data.map((change) => {
							let classes = 'changes-group';
							if (change.active) {
								// add this class if a change is currently happening
								classes += ' active-change';
							}
							if (change.potentialOutage) {
								// add this class if there is a potential cause for outage
								classes += ' potential-outage';
							}
							return (
								<Change
									classes={classes}
									desc={change.description}
									startTime={change.startTime}
									endTime={change.endTime}
									link={change.jiraLink}
								/>
							);
						})
					);
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		if (result) {
			console.log(result);
		}
		window.addEventListener('resize', showDashboard);
		getOutageData();
		const interval = setInterval(() => {
			getOutageData();
			if (error) {
				console.log(error);
				return;
			}
		}, 15000);
		// event listener to tell when the page has been resized

		return () => {
			clearInterval(interval);
		};
	}, []);
	// Will ensure the time is always accurate
	useEffect(() => {
		const interval = setInterval(() => {
			setDateTime(
				`${daySerializer[new Date().getDay()]} - ${formatTime(
					new Date().getHours() + ':' + new Date().getMinutes()
				)}`
			);
		}, 6000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<>
			<ThemeContextConsumer>
				{(context) => (
					<div className={`${context.theme}`}>
						{!isFullscreen ? (
							<>
								<Navbar bg={context.theme} variant={context.theme}>
									<Container fluid>
										<Navbar.Brand href='https://blackops.uvu.edu'>
											<img
												alt='OIT Logo'
												src={logo}
												width='55'
												height='50'
												className='d-inline-block align-top'></img>
											<h1 className='title'>{' UVU BlackOps '}</h1>
										</Navbar.Brand>
										<Col xl='2'>
											{context.theme === 'dark' ? (
												<h1 style={{ color: 'white' }}>Outages</h1>
											) : (
												<h1 style={{ color: 'black' }}>Outages</h1>
											)}
										</Col>
										<Nav>
											<input
												className='dark-mode-input'
												type='checkbox'
												id='darkmode-toggle'
												onChange={context.toggleTheme}
												checked={context.theme === 'dark' ? '' : 'checked'}
											/>
											<label
												className='dark-mode-label'
												style={{
													background:
														context.theme === 'dark' ? '#242424' : '#ebebeb',
												}}
												htmlFor='darkmode-toggle'>
												<MoonLogo
													fill={context.theme === 'dark' ? '#fff' : '#7e7e7e'}
													className='moon joint'
												/>
												<SunLogo
													fill={context.theme === 'dark' ? '#7e7e7e' : '#fff'}
													className='sun joint'
												/>
											</label>
											{isAuthenticated ? <SignOutButton /> : <SignInButton />}
										</Nav>
									</Container>
								</Navbar>
								<a className='report' href='https://uvu.edu/servicedesk'>
									Report Outage
								</a>
							</>
						) : null}

						<Container fluid className='footer-space'>
							<Row className={`main-block`} id='service-incidents'>
								<Row className='title-block'>
									<Col>Service Incidents</Col>
									<Col xl='auto' id='on-call'>
										On Call: {onCall}
									</Col>
									<Col id='time'>{dateTime}</Col>
								</Row>
								<Row className='all-incidents'>{incidents}</Row>
							</Row>
							<Row id='alerts-changes'>
								<Col
									className={`main-block`}
									id='alerts'
									style={{
										maxHeight: incidents.length > 2 ? '50vh' : '70vh',
										height: incidents.length > 2 ? '50vh' : '70vh',
									}}>
									<Row className='title-block'>
										<Col xl='auto'>Alerts</Col>

										<Col
											xl={2}
											style={{
												visibility: isFullscreen ? 'hidden' : 'visible',
											}}>
											<Form.Group className='move'>
												<Form.Label className='label'>Sort By:</Form.Label>
												<Form.Select
													onChange={getFilteredAlerts}
													className='remove-space'
													id='sort-filter'>
													<option value='createdAt'>Created</option>
													<option value='updatedAt'>Last Updated</option>
													<option value='acknowledged'>Acknowledged</option>
												</Form.Select>
											</Form.Group>
										</Col>
										<Col
											className='remove-padding'
											style={{
												visibility: isFullscreen ? 'hidden' : 'visible',
											}}>
											<Form.Group className='move'>
												<Form.Label className='label'>Team Filter:</Form.Label>
												<Form.Select
													onChange={getFilteredAlerts}
													className='remove-space'
													id='team-filter'>
													<option value='All'>All</option>
													{teamOpts}
												</Form.Select>
											</Form.Group>
										</Col>
										<Col
											xl={2}
											style={{
												visibility: isFullscreen ? 'hidden' : 'visible',
											}}>
											<Form.Group className='move'>
												<Form.Label className='label'>Priority:</Form.Label>
												<Form.Select
													onChange={getFilteredAlerts}
													className='remove-space'
													id='priority-filter'>
													<option value='Priority 3+'>Priority 3+</option>
													<option value='All'>All Priorities</option>
												</Form.Select>
											</Form.Group>
										</Col>
									</Row>
									{!isFullscreen ? (
										<Row
											className='all-changes'
											style={{
												paddingRight: '10px',
												maxHeight: incidents.length > 2 ? '40vh' : '60vh',
											}}>
											{alerts}
										</Row>
									) : (
										<Row
											className='all-changes'
											style={{
												overflow: 'hidden',
												maxHeight: incidents.length > 2 ? '40vh' : '60vh',
											}}>
											{alerts}
										</Row>
									)}
								</Col>
								<Col
									className={`main-block`}
									id='scheduled-change'
									style={{
										maxHeight: incidents.length > 2 ? '50vh' : '70vh',
										height: incidents.length > 2 ? '50vh' : '70vh',
									}}>
									<Row className='title-block'>
										<Col>Scheduled Changes</Col>
									</Row>
									{!isFullscreen ? (
										<Row
											className='all-changes'
											style={{
												paddingRight: '10px',
												maxHeight: incidents.length > 2 ? '40vh' : '60vh',
											}}>
											{changes}
										</Row>
									) : (
										<Row
											className='all-changes'
											style={{
												overflow: 'hidden',
												maxHeight: incidents.length > 2 ? '40vh' : '60vh',
											}}>
											{changes}
										</Row>
									)}
								</Col>
							</Row>
						</Container>
						{!isFullscreen ? (
							<Navbar className='footer'>
								<div className='footer-data'>
									<a
										className='footer-link'
										href='https://www.uvu.edu/oit/infra/itops/'>
										IT Operations
									</a>
									{' | '}
									<a className='footer-link' href='mailto:operators%40uvu.edu'>
										operators@uvu.edu
									</a>
									{' | (801) 863-5247 | Room FL-323 '}
								</div>
							</Navbar>
						) : null}
					</div>
				)}
			</ThemeContextConsumer>
		</>
	);
}
