import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { ThemeContextConsumer } from '../ThemeContext.js';

export default function SignInButton() {
	const { instance } = useMsal();

	const handleSignIn = () => {
		instance.loginRedirect();
	};
	return (
		<ThemeContextConsumer>
			{(context) => <Button onClick={handleSignIn} variant={context.theme}>Sign in</Button>}
		</ThemeContextConsumer>
	);
}
