 import React, { useState } from 'react';

const { Provider, Consumer } = React.createContext();
const lsTheme = localStorage.getItem('theme')
	? localStorage.getItem('theme')
	: 'dark';
function ThemeContextProvider(props) {
	const [theme, setTheme] = useState(lsTheme);
	const toggleTheme = () => {
		setTheme(theme === 'dark' ? 'light' : 'dark');
		localStorage.setItem('theme', theme === 'dark' ? 'light' : 'dark');
	};
	return <Provider value={{ theme, toggleTheme }}>{props.children}</Provider>;
}
export { ThemeContextProvider, Consumer as ThemeContextConsumer };