import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getHexColor, getRGBAColor } from '../priorityColors.js';

export default function Incident(props) {
	return (
		<Col xl={12} xxl={6}>
			<a href={props.link}>
				<Row
					className='incident'
					style={{ backgroundColor: `${getRGBAColor(props.priority)}` }}>
					<Col
						xs={2}
						id='incident-priority'
						style={{ backgroundColor: `${getHexColor(props.priority)}` }}>
						<div id='p-val'>{props.priority}</div>
					</Col>
					<Col className='incident-desc'>
						<Row id='incident-details'>{props.details}</Row>
						<Row>
							<Col id='incident-date'>{props.date}</Col>
						</Row>
					</Col>
				</Row>
			</a>
		</Col>
	);
}
