import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getHexColor, getRGBAColor } from '../priorityColors.js';

export default function Alerts(props) {
	return (
		<Row className='alert-group' id={props.flash} style={{ backgroundColor: `${getRGBAColor(props.priority)}` }}>
			<Col xs={2} id='alert-priority' style={{ backgroundColor: `${getHexColor(props.priority)}` }}>
				<div id='p-val'>{props.priority}</div>
			</Col>
			<Col className='alert-details'>
				<Row>
					{props.ack === null ? null : (
						<Col xs='auto' id='alert-ack' style={{ backgroundColor: `${getRGBAColor(props.priority)}` }}>
							{props.ack}
						</Col>
					)}
					<Col id='alert-date'>{props.date}</Col>
				</Row>

				<Row id='alert-desc'>
					<Col>{props.desc}</Col>
				</Row>
			</Col>
		</Row>
	);
}
